@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
4. Menu
5. Home
6. Featured Album
7. Shows
8. Artist
9. Extra
10. Footer


******************************/

/***********
1. Fonts
***********/

/* @font-face
{
	font-family: 'Source Sans Pro';
	src: url('../../public/fonts/Source Sans ProLT-Book.ttf')  format('truetype');
} */

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body {
    font-family: 'Source Sans Pro', sans-serif; /* Updated font family */
    font-size: 16px;
    font-weight: 400;
    background: #FFF;
    color: #000000;
}

div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 14px;
	line-height: 2.18;
	font-weight: 400;
	color: #000000;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #0aae4d;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #0aae4d;
}
p a:hover
{
	color: #000000;
	background: #0aae4d;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: rgba(255,255,255,0.07);
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Source Sans Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
	line-height: 1.2;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
button:active
{
	outline: none;
}
.form-control
{
	color: #0aae4d;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.background_image
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.nopadding
{
	padding: 0px !important;
}
.section_subtitle
{
	font-size: 18px;
	line-height: 0.75;
}
.section_title
{
	margin-top: 7px;
	margin-left: -2px;
}

/*********************************
3. Header
*********************************/

.header
{
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background: #333232;
	z-index: 100;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled
{
	background: rgba(0,0,0,0.85);
}
.header_content
{
	width: 100%;
	height: 101px;
	padding-left: 51px;
	padding-right: 52px;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header.scrolled .header_content
{
	height: 70px;
}
.logo
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 51px;
}
.logo a
{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 30px;
	color: #0aae4d;
}
.log_reg
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 52px;
}
.log_reg ul li
{
	position: relative;
}
.log_reg ul li:not(:last-child)::after
{
	display: inline-block;
	content: '/';
	font-size: 14px;
	color: #FFF;
	margin-left: 6px;
	margin-right: 6px;
}
.log_reg ul li a
{
	font-size: 14px;
	color: #FFF;
}
.log_reg ul li a:hover
{
	color: #0aae4d;
}
.main_nav ul li:not(:last-child)
{
	margin-right: 24px;
}
.main_nav ul li
{
	position: relative;
}
.main_nav ul li a
{
	position: relative;
	font-size: 16px;
	color: #FFF;
	padding-left: 26px;
	padding-right: 23px;
	z-index: 1;
}
.main_nav ul li a::after
{
	display: block;
	position: absolute;
	left: 0;
	bottom: -20px;
	width: 100%;
	height: 5px;
	background: #525252;
	content: '';
	z-index: -1;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.main_nav ul li.active a::after,
.main_nav ul li a:hover::after
{
	bottom: -5px;
	visibility: visible;
	opacity: 1;
}
.hamburger
{
	display: none;
	width: 50px;
	height: 24px;
	cursor: pointer;
	padding: 5px;
	right: -5px;
}
.hamburger > div
{
	width: 100%;
	height: 100%;
}
.hamburger > div > div
{
	height: 2px;
	background: #000000;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.hamburger > div > div:first-child
{
	width: 90%;
}
.hamburger > div > div:nth-child(2)
{
	width: 50%;
}
.hamburger > div > div:nth-child(3)
{
	width: 70%;
}
.hamburger:hover > div > div
{
	width: 100%;
}

/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 101;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 800ms ease;
	-moz-transition: all 800ms ease;
	-ms-transition: all 800ms ease;
	-o-transition: all 800ms ease;
	transition: all 800ms ease;
}
.menu_overlay
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.45);
}
.menu.active
{
	visibility: visible;
	opacity: 1;
}
.menu > div
{
	width: 100%;
	height: 100%;
}
.menu_container
{
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: -300px;
	width: 300px;
	-webkit-transition: all 1000ms 600ms ease;
	-moz-transition: all 1000ms 600ms ease;
	-ms-transition: all 1000ms 600ms ease;
	-o-transition: all 1000ms 600ms ease;
	transition: all 1000ms 600ms ease;
}
.menu.active .menu_container
{
	right: 0;
}
.menu_log_reg
{
	display: none;
	width: 100%;
	background: #FFF;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
	margin-bottom: 5px;
	border-bottom: solid 2px #0aae4d;
}
.menu_log_reg a
{
	font-size: 14px;
	color: #000000;
}
.menu_log_reg a:hover
{
	color: #0aae4d;
}
.menu_log_reg ul li
{
	position: relative;
}
.menu_log_reg ul li:not(:last-child)::after
{
	display: inline-block;
	content: '/';
	font-size: 14px;
	color: #000000;
	margin-left: 6px;
	margin-right: 6px;
}
.menu_nav
{
	width: 100%;
	background: #FFF;
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 50px;
	padding-bottom: 50px;
	border-bottom: solid 5px #0aae4d;
}
.menu_nav ul li
{
	position: relative;
}
.menu_nav ul li a:hover::before
{
	left: 100%;
}
.menu_nav ul li:not(:last-of-type)
{
	margin-bottom: 5px;
}
.menu_nav ul li a
{
	display: block;
	position: relative;
	font-size: 20px;
	color: #000000;
	overflow: hidden;
}
.menu_nav ul li a::before
{
	display: block;
	position: absolute;
	top: 50%;
	left: -100%;
	width: 100%;
	height: 2px;
	background: #000000;
	content: '';
	-webkit-transition: all 400ms 200ms ease;
	-moz-transition: all 400ms 200ms ease;
	-ms-transition: all 400ms 200ms ease;
	-o-transition: all 400ms 200ms ease;
	transition: all 400ms 200ms ease;
}
.menu_nav ul li a:hover
{
	color: #0aae4d;
}

/*********************************
5. Home
*********************************/

.home
{
	width: 100%;
	height: 400px;
	padding-left: 51px;
	padding-right: 52px;
}
.home_slider_container
{
	width: 100%;
	height: 100%;
}
.home_slider.owl-theme .owl-nav.disabled + .owl-dots
{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	bottom: 24px;
	z-index: 10;
}
.home_slider.owl-theme .owl-dots .owl-dot span
{
	width: 14px;
	height: 14px;
	background: #4d4d4d;
	border-radius: 0px;
	margin-left: 3px;
	margin-right: 3px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.home_slider.owl-theme .owl-dots .owl-dot.active span,
.home_slider.owl-theme .owl-dots .owl-dot:hover span
{
	background: #FFF;
}
.home_container
{
	width: 100%;
	height: 100%;
	z-index: 1;
	padding-top: 72px;
}
.home_container_inner
{
	width: 100%;
	height: 100%;
}
.home_content
{

}
.home_subtitle
{
	font-size: 18px;
	color: #000000;
}
.home_title
{
	margin-top: 10px;
}
.home_title h1
{
	font-size: 62px;
}
.home_link
{
	margin-top: 10px;
}
.home_link a
{
	position: relative;
	padding-left: 17px;
	padding-right: 12px;
	font-size: 24px;
	color: #000000;
}
.home_link a::before
{
	display: block;
	position: absolute;
	left: 0;
	bottom: -4px;
	width: 100%;
	height: 8px;
	background: #FFF;
	content: '';
	z-index: -1;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.home_link a:hover::before
{
	background: #0aae4d;
}

/*********************************
6. Featured Album
*********************************/

.featured_album
{
	padding-top: 107px;
}
.featured_background
{
	top: auto;
	bottom: -185px;
	max-height: 489px;
}
.featured_album .section_title_container
{
	padding-left: 20px;
}
.featured_row
{
	margin-top: 71px;
}
.featured_album_image
{
	width: calc(100% + 42px);
	height: 722px;
}
.featured_album_image img
{
	max-width: 100%;
}
.image_overlay
{
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: #0aae4d;
	z-index: 1;
	-webkit-transition: all 1000ms ease;
	-moz-transition: all 1000ms ease;
	-ms-transition: all 1000ms ease;
	-o-transition: all 1000ms ease;
	transition: all 1200ms ease;
}
.image_overlay.active
{
	width: 0%;
}
.featured_album_col
{
	z-index: 1;
}
.featured_album_player_container
{
	width: calc(100% + 58px);
	left: -58px;
	height: 100%;
}
.featured_album_player
{
	width: 100%;
	border: solid 1px #d3d3d3;
	background: #333232;
}
.featured_album_title_bar
{
	height: 146px;
	padding-left: 55px;
	padding-right: 46px;
}
.featured_album_title_container
{
	
}
.featured_album_artist
{
	font-size: 30px;
	color: #000000;
	line-height: 1.2;
}
.featured_album_title
{
	font-size: 16px;
	color: rgba(255,255,255,0.67);
	margin-top: -2px;
}
.featured_album_link
{
	width: 181px;
	height: 42px;
	background: #0aae4d;
	text-align: center;
}
.featured_album_link a
{
	display: block;
	width: 100%;
	height: 100%;
	font-size: 12px;
	color: #000000;
	text-transform: uppercase;
	line-height: 42px;
}
.jp-audio
{
	width: 100%;
}
.jp-playlist
{
	background: #333232;
}
.jp-playlist ul
{
	/*padding-left: 55px;*/
}
.jp-playlist li
{
	position: relative;
	height: 38px;
	border-bottom: none;
	padding-left: 55px;
}
.jp-playlist li::before,
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current::before
{
	display: block;
	position: absolute;
	top: 0;
	left: -4px;
	width: calc(100% + 8px);
	height: 100%;
	background: #5c5c5c;
	visibility: hidden;
	opacity: 0;
	content: '';
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.jp-playlist li:hover::before,
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current::before
{
	opacity: 1;
	visibility: visible;
}
.jp-playlist li > div
{
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	padding-left: 35px;
	padding-right: 45px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.song_duration
{
	margin-left: auto;
}
.jp-playlist li > div::before
{
	display: block;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	font-family: 'FontAwesome';
	content: '\f04b';
	color: rgba(255,255,255,0.5);
}
.jp-playlist li:not(:last-child)
{
	margin-bottom: 14px;
}
.jp-type-playlist div.jp-playlist a,
.song_duration
{
	font-family: 'Source Sans Pro', sans-serif !important;
	font-size: 16px !important;
	color: rgba(255,255,255,0.5) !important;
	line-height: 38px;
	outline: none !important;
}
.jp-type-playlist div.jp-playlist a > span
{
	display: none;
}
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current > div
{
	padding-left: 26px;
}
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current > div::before
{
	color: #000000;
}
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current a,
#jp_container_1.jp-state-playing .jp-playlist li.jp-playlist-current > div .song_duration
{
	color: #000000 !important;
}
.jp-details
{
	display: block !important;
}
.player_details
{
	width: 100%;
	height: 92px;
	background: #000000;
	margin-top: 27px;
	padding-left: 25px;
	padding-right: 22px;
}
.jp-details > div
{
	font-size: 12px;
	color: rgba(51,50,50,0.5);
	text-transform: uppercase;
}
.jp-details > div:first-child
{
	
}
.jp-details > div:first-child
{
	display: none;
}
.jp-state-playing .jp-details > div:first-child
{
	display: block;
}
.jp-state-playing .jp-details .jp-title
{
	display: block;
}
.jp-state-playing .jp-details > div:first-child
{
	display: block !important;
}
.jp-title
{
	font-size: 16px !important;
	color: #333232 !important;
	text-transform: none !important;
}
.jp-play
{
	position: relative;
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: #0aae4d;
	border: none;
	cursor: pointer;
	text-align: center;
	outline: none !important;
}
.jp-play::after
{
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-family: 'FontAwesome';
	font-size: 20px;
	color: #000000;
	content: '\f04b';
}
.jp-state-playing .jp-play::after
{
	content: '\f04c';
}
.jp-play:active
{
	width: 46px;
	height: 46px;
}
.player_controls
{
	width: 100%;
	background: #ececec;
}
.player_controls .jp-interface
{
	width: 100%;
	height: 56px;
	padding-left: 23px;
	padding-right: 20px;
}
.jp-volume-controls
{
	width: 80px;
}
.jp-volume-bar
{
	width: 45px;
	height: 9px;
	background: #989898;
	cursor: pointer;
}
.jp-volume-bar-value
{
	height: 9px;
	background: #5f5f5f;
}
.jp-mute
{
	position: relative;
	width: 29px;
	height: 16px;
	background: transparent;
	border: none;
	outline: none;
	margin-right: 6px;
	cursor: pointer;
}
.jp-state-muted .jp-mute::after
{
	content: url(../../public/Assets/mute_0.png);
}
.jp-mute:active
{
	outline: none;
}
.jp-mute::after
{
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: url(../../public/Assets/mute_1.png);
}
.time_controls
{
	width: calc(100% - 110px);
}
.jp-progress
{
	width: 100%;
	height: 9px;
	background: #9f9f9f;
}
.jp-current-time
{
	width: 48px;
}
.jp-current-time,
.jp-duration
{
	font-size: 14px;
	color: #333232 !important;
}
.jp-duration
{
	width: 56px;
	text-align: right;
}
.jp-progress
{
	cursor: pointer;
}
.jp-play-bar
{
	height: 9px;
	background: #5f5f5f;
}

/*********************************
7. Shows
*********************************/

.shows
{
	padding-top: 50px;
}
.shows_row
{
	margin-top: -55px;
}
.shows_list_col
{
	z-index: 1;
}
.shows_image
{
	left: -98px;
	width: calc(100% + 252px);
}
.shows_image img
{
	max-width: 100%;
}
.shows_image .image_overlay
{
	z-index: 0;
}
.shows_list_container
{
	width: calc(100% + 13px);
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}
.shows_list li
{
	width: 100%;
	/* height: 120px; */
	min-height: 120px;
	max-height: 200px;
	background: rgba(0, 0, 0, 0.2); /* Light gray background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	padding-left: 11px;
	margin-bottom: 15px;
	padding-right: 37px;
	color: #000000;
	font-size: 18px;
	transition: background 0.3s ease;
}
.shows_list li a
{
	color: #000000;
}
.shows_list li:not(:last-of-type)
{
	margin-bottom: 5px;
}
.show_date
{
	width: 78px;
	height: 60px;
	background: #0aae4d;
	line-height: 60px;
	text-align: center;
	margin-right: 23px;
}
.show_info
{
	width: 100%;
	height: 100%;
	padding-right: 23px;
}
.show_name
{
	width: 50%;
	margin-right: 23px;
}
.show_name a:hover
{
	color: #0aae4d;
}
.show_location
{
	width: calc(50% - 23px);
	margin-left: 23px;
}
.show_shop {
	width: 130px;
	height: 40px;
	background: #0aae4d;
	color: #FFF;  /* Text color */
	border: none;
	/* border-radius: 5px; */
	cursor: pointer;
	transition: background 0.3s ease-in-out;  /* Smooth transition effect */
  }
.show_shop:hover
{
	background: #cccccc;
	border: 2px solid #0aae4d;
	color: #0aae4d;
}
.show_shop a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 40px;
	text-align: center;
	color: #FFF;
}
/*********************************
8. Artist
*********************************/

.artist
{
	padding-bottom: 106px;
	background: #FFF;
}
.artist_content
{
	padding-top: 191px;
}
.artist_image
{
	width: calc(100% + 205px);
	left: -185px;
	padding-right: 20px;
}
.artist_image img
{
	max-width: 100%;
}
.artist_text
{
	margin-top: 39px;
}
.artist_text p:not(:last-child)
{
	margin-bottom: 30px;
}
.artist_sig
{
	margin-top: 51px;
	margin-left: 26px;
}
.single_player_container
{
	width: calc(100% + 12px);
	background: #000000;
	left: -10px;
	margin-top: 62px;
}
.single_player
{
	width: 100%;
}
.jp-type-single .player_details
{
	margin-top: 0;
	padding-left: 46px;
	padding-right: 54px;
}
.jp-type-single .player_controls .jp-interface
{
	padding-left: 44px;
	padding-right: 52px;
}
.jp-type-single .jp-play
{
	-webkit-transform: translateY(7px);
	-moz-transform: translateY(7px);
	-ms-transform: translateY(7px);
	-o-transform: translateY(7px);
	transform: translateY(7px);
}

/*********************************
9. Extra
*********************************/

.extra
{
	padding-left: 50px;
	padding-right: 51px;
}
.extra_container
{
	
}
.extra_content
{
	height: 448px;
	-webkit-transform: translateY(-5px);
	-moz-transform: translateY(-5px);
	-ms-transform: translateY(-5px);
	-o-transform: translateY(-5px);
	transform: translateY(-5px);
}
.extra_title h1
{
	font-size: 60px;
}
.extra_text
{
	margin-top: 4px;
}
.extra_button
{
	width: 161px;
	height: 46px;
	background: #FFF;
	margin-top: 35px;
}
.extra_button:hover
{
	background: rgba(0,0,0,0.5);
}
.extra_button a
{
	display: block;
	width: 100%;
	height: 100%;
	line-height: 46px;
	color: #000000;
	font-size: 14px;
	text-align: center;
}

/*********************************
10. Footer
*********************************/

.footer
{
	background: #333232;
	padding-left: 50px;
	padding-right: 51px;
}
.newsletter_container
{
	width: 604px;
	padding-top: 103px;
}
.newsletter_title
{

}
.newsletter_form
{
	display: block;
	position: relative;
	width: 100%;
	margin-top: 30px;
}
.newsletter_input
{
	width: 100%;
	height: 50px;
	background: rgba(0, 0, 0, 0.2);
	border: none;
	outline: none;
	padding-left: 23px;
}

.newsletter_input2
{
	width: 100%;
	height: 50px;
	border: none;
	background: rgba(0, 0, 0, 0);
	outline: none;
	padding-left: 23px;
}
.newsletter_input::-webkit-input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_input:-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_input::-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
} 
.newsletter_input:-ms-input-placeholder
{ 
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_input::input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}





.newsletter_input2::-webkit-input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_input2:-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_input2::-moz-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
} 
.newsletter_input2:-ms-input-placeholder
{ 
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_input2::input-placeholder
{
	font-size: 12px !important;
	font-style: italic;
	color: #000000 !important;
}
.newsletter_button
{
	position: absolute;
	top: 0;
	right: 0;
	width: 139px;
	height: 50px;
	background: #0aae4d;
	border: none;
	outline: none;
	cursor: pointer;
	color: #000000;
	font-size: 14px;
}
.footer_lists
{
	width: 575px;
	padding-top: 83px;
	padding-bottom: 5px;
}
.footer_list
{
	width: calc(100% / 3);
}
.footer_list_title
{
	font-size: 24px;
}
.footer_list ul
{
	margin-top: 23px;
}
.footer_list ul li:not(:last-of-type)
{
	margin-bottom: 9px;
}
.footer_list ul li a
{
	font-size: 14px;
	color: #FFF;
}
.footer_list ul li a:hover
{
	color: #000000;
}
.copyright_bar
{
	width: 100%;
	height: 67px;
	background: #000;
}
.copyright_bar span
{
	font-size: 14px;
	color: #FFF;
	line-height: 67px;
}