.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.super_container {
  width: 100%;
  overflow: hidden;
}

.contact {
  padding-top: 101px;
  padding-bottom: 105px;
}
.contact_title {
  font-size: 36px;
  color: #ffffff;
  line-height: 1.2;
}
.contact_form {
  display: block;
  width: 100%;
  margin-top: 59px;
}
.contact_input {
  position: relative;
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  color: #000;
  /* border: 2px solid #343434; */
  border: none;
  outline: none;
  padding-left: 25px;
  /* border-bottom: solid 3px transparent; */
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.contact_input:hover,
.contact_input:focus {
  border-bottom: solid 3px #0aae4d;
}
.contact_textarea {
  height: 161px;
  padding-top: 18px;
}
.contact_form input:not(:last-child) {
  margin-bottom: 20px;
}
.contact_input::-webkit-input-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #000 !important;
}
.contact_input:-moz-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #000 !important;
}
.contact_input::-moz-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #ffffff !important;
}
.contact_input:-ms-input-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #ffffff !important;
}
.contact_input::input-placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  font-style: italic;
  color: #ffffff !important;
}
.contact_button {
  width: 173px;
  height: 50px;
  background: #0aae4d;
  color: #ffffff;
  font-size: 14px;
  border: solid 3px #0aae4d;
  outline: none;
  cursor: pointer;
  margin-top: 39px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.contact_button:hover {
  background: transparent;
  color: #0aae4d;
}
.contact_text {
  margin-top: 30px;
}

.hamburger {
  cursor: pointer;
  z-index: 999999999999999999; /* To ensure it remains clickable */
}